import './bootstrap';
import * as bootstrap from 'bootstrap';
import { tns } from "tiny-slider/src/tiny-slider";

new bootstrap.Tooltip("body", {
    selector: "[data-bs-toggle='tooltip']"
});

if (document.querySelectorAll('.new-slider').length) {
    tns({
        container: '.new-slider',
        items: 1,
        gutter: 20,
        autoplayButtonOutput: false,
        // center: true,
        controls: true,
        // autoWidth: true,
        controlsContainer: '.controlsContainer',
        nav: false,
        // fixedWidth: 250,
        // viewportMax: 250,
        responsive: {
            600: {items: 2},
            900: {items: 3},
        }
    });
}

$('header .site-menu-toggle').on('click', function (e) {
    $('header').toggleClass('open-mobile-menu');
    e.preventDefault();
});

Livewire.on('refreshCart', reloadPaczkomatModal);

let paczkomatyInit = false;

if (document.getElementById('paczkomatModal')) {
    var paczkomatModalElem = document.getElementById('paczkomatModal');

    paczkomatModalElem.addEventListener('show.bs.modal', function (event) {
        window.easyPackAsyncInit = function () {
            paczkomatyInit = false;
            initPaczkomatyMap();
        };
    });
}

function reloadPaczkomatModal() {
    var paczkomatModalElem = document.getElementById('paczkomatModal');

    if ( ! paczkomatModalElem) {
        return false;
    }

    paczkomatyInit = false;

    paczkomatModalElem.removeEventListener('shown.bs.modal', initPaczkomatyMap);
    paczkomatModalElem.addEventListener('shown.bs.modal', initPaczkomatyMap);
}

function initPaczkomatyMap() {
    var paczkomatModalElem = document.getElementById('paczkomatModal');

    if (paczkomatyInit === false) {
        paczkomatyInit = true;

        easyPack.init({
            defaultLocale: 'pl',
            points: {
                types: ['parcel_locker']
            },
        });

        var map = easyPack.mapWidget('easypack-map', function (point) {
            // console.log(point);

            Livewire.emit('paczkomatChanges', point.name, point.address.line1 + '<br>' + point.address.line2);

            paczkomatModalElem.querySelector('.btn-close').click();
        });
    }
}

Livewire.on('showInfo', (message, level) => {
    toastr.options = {
        "closeButton": true,
        "positionClass": "toast-top-right",
    }

    toastr[level](message);
})

if (document.querySelectorAll('.product-slider').length) {
    tns({
        container: '.product-slider',
        items: 1,
        gutter: 20,
        autoplayButtonOutput: false,
        // center: true,
        controls: true,
        // autoWidth: true,
        autoHeight: true,
        controlsContainer: '.product-controls',
        navContainer: ".product-thumbnails",
        navAsThumbnails: true,
    });
}
